import { render, staticRenderFns } from "./MedicalCenterCreate.vue?vue&type=template&id=148f91ba&scoped=true&"
import script from "./MedicalCenterCreate.vue?vue&type=script&lang=js&"
export * from "./MedicalCenterCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148f91ba",
  null
  
)

export default component.exports